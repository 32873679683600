import React, { ReactNode } from 'react';
import { Spinner } from 'flowbite-react';

import useProfileImage from '@/hooks/useProfileImage';
import { ThingWithProfileImage } from '@/services/thingWithProfileImage';

interface Props {
    readonly externalStyles?: string;
    readonly height?: string;
    readonly width?: string;
    readonly children?: ReactNode;
    readonly brief: ThingWithProfileImage;
}

function ProfileImage({
    externalStyles = '',
    height = 'h-[144px]',
    width = 'w-[144px]',
    children = null,
    brief,
}: Props) {
    const effectiveName = brief.displayName || brief.name || brief.email || brief.primaryEmail;

    const { loading, image } = useProfileImage(brief);

    const classNames = `${height} ${width} m-auto`;

    return (
        <div className={`${classNames} ${externalStyles}`}>
            {loading
                ? <Spinner size="xl" className={`${height} ${width}`} />
                : <img src={image} alt={effectiveName} className="object-cover rounded-full w-full h-full" />}
            {children}
        </div>
    );
}

export default ProfileImage;
