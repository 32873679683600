import React from 'react';

interface Props {
    readonly className?: string;
}

export default function XTwitter({
    className = null,
}: Props) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 16 16" fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1624 1.5H14.3687L9.5499 7.00625L15.2187 14.5H10.7812L7.30303 9.95625L3.32803 14.5H1.11865L6.27178 8.60938L0.837402 1.5H5.3874L8.52803 5.65312L12.1624 1.5ZM11.3874 13.1812H12.6093L4.72178 2.75H3.40928L11.3874 13.1812Z" />
        </svg>
    );
}
