import React from 'react';

import Instagram from '@/components/commons/icons/Instagram';
import XTwitter from '@/components/commons/icons/XTwitter';
import Facebook from '@/components/commons/icons/Facebook';
import LinkedIn from '@/components/commons/icons/LinkedIn';
import LinkIcon from '@/components/commons/icons/LinkIcon';
import GitHub from '@/components/commons/icons/GitHub';

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const wordCount = (text, showFull, limit) => {
    let counter = 0;
    const output = [];

    if (!text) {
        return null;
    }

    const separatedParagraphs = text.split('\n');

    const longText = text.split(' ').length > limit;

    if (showFull) {
        return { text: separatedParagraphs, long: longText };
    }

    separatedParagraphs.forEach((e) => {
        if (counter < limit) {
            const wordsRemaining = limit - counter;
            const newOutput = e.split(' ').slice(0, wordsRemaining);
            counter += newOutput.length;
            output.push(newOutput.join(' '));
        }
    });

    return { text: output, long: longText };
};

export const getUTCDate = (dateString) => {
    if (!dateString) {
        return 'N/A';
    }
    const date = new Date(dateString);

    return `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}`;
};

export const getFullBirthday = (dateString) => {
    if (!dateString) {
        return 'N/A';
    }
    const birthday = new Date(dateString);

    return `${birthday.getUTCFullYear()}-${months[birthday.getUTCMonth()]}-${birthday.getUTCDate()}`;
};

export const getBirthday = (dateString) => {
    if (!dateString) {
        return 'N/A';
    }
    const birthday = new Date(dateString);

    return `${months[birthday.getUTCMonth()]}. ${birthday.getUTCDate()}`;
};

export const getYear = (dateString) => {
    if (!dateString) {
        return 'present';
    }

    return new Date(dateString).getUTCFullYear();
};

export const formatSocialNames = (name, url) => {
    if (!name || !url) {
        return '';
    }

    const isNotStandarized = name.startsWith('other');

    const domains = {
        linkedIn: 'https://www.linkedin.com/in/',
        xTwitter: 'https://www.x.com/',
        instagram: 'https://www.instagram.com/',
        facebook: 'https://www.facebook.com/',
        gitHub: 'https://www.github.com/',
    };

    const icons = {
        linkedIn: <LinkedIn className="text-cleanSlate" />,
        xTwitter: <XTwitter className="text-cleanSlate" />,
        instagram: <Instagram className="text-cleanSlate" />,
        facebook: <Facebook className="text-cleanSlate" />,
        gitHub: <GitHub className="text-cleanSlate" />,
        other_1: <LinkIcon className="text-cleanSlate" />,
        other_2: <LinkIcon className="text-cleanSlate" />,
    };

    const cleanedUrl = url.replace(domains[name], '').replace(/\/$/, '');
    const finalUrl = isNotStandarized ? url.replace(/^https?:\/\//, '') : `@${cleanedUrl}`;

    return (
        <li key={`social-${name}`} className="flex">
            <div className="flex items-start justify-center mr-2 mt-1">
                {icons[name]}
            </div>
            <a
                target="_blank"
                href={url}
                className="text-progressBarBlue mb-2 break-all"
                rel="noreferrer"
            >
                {finalUrl}
            </a>
        </li>
    );
};

export const formatWorkHistoryItems = (data) => {
    let output = [];

    if (!data) {
        return [];
    }

    output = data.map((item) => ({
        organization: item.organization || '',
        title: item.title || '',
        description: item.description || '',
        startAt: item.startAt || null,
        endAt: item.endAt || null,
    }));

    return output;
};

export const checkWorkHistoryMissingFields = (workHistory) => {
    if (!workHistory || workHistory?.length === 0) {
        return [];
    }
    const hasErrors = [];
    workHistory.forEach((element) => {
        if (!element.title || !element.organization || !element.startAt) {
            return hasErrors.push(true);
        }
        return hasErrors.push(false);
    });

    return hasErrors;
};

export const handleRangeToYears = (value, index, updateWorkHistoryField) => {
    if (!value.period) {
        return null;
    }
    let endAt = new Date(value.period.endDate);
    const startAt = new Date(value.period.startDate);

    const hasSelectedEndDate = (startAt.getTime() !== endAt.getTime());

    if (!hasSelectedEndDate) {
        endAt = null;
    }

    updateWorkHistoryField(index, 'startAt', startAt);
    updateWorkHistoryField(index, 'endAt', endAt);

    return { startAt, endAt };
};
