import { useEffect, useState } from 'react';
import fetch from 'cross-fetch';

import { ThingWithProfileImage } from '@/services/thingWithProfileImage';

const DEFAULT_GRAVATAR_PATH = '/images/default-gravatar.svg';

// See details here: https://en.gravatar.com/site/implement/images/
// enum GravatarDefaultOpts {
//    // Using NotFound DefaultOpt makes gravatar return a 404 if not found instead of default
//    // we use this to fallback to our own default image
//     NotFound = '404',
//     Mp = 'mp',
//     Identicon = 'identicon',
//     MonsterId = 'monsterid',
//     Wavatar = 'wavatar',
//     Retro = 'retro',
//     RoboHash = 'robohash',
//     Blank = 'blank'
// }

function getGravatarUrl(emailHash: string) {
    return `https://www.gravatar.com/avatar/${emailHash}/?d=404`;
}

export default function useProfileImage(thing: ThingWithProfileImage) {
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(DEFAULT_GRAVATAR_PATH);
    const [isGravatar, setIsGravatar] = useState(false);

    const { emailHash, profileImageUrl, photoImageUrl } = thing;

    useEffect(() => {
        const fetchImage = async () => {
            const effectiveProfileImageUrl = profileImageUrl?.x2
                || profileImageUrl?.x1
                || profileImageUrl?.original;

            if (effectiveProfileImageUrl) {
                setImage(effectiveProfileImageUrl);
                setIsGravatar(false);
                setLoading(false);
            } else if (photoImageUrl) {
                setImage(photoImageUrl);
                setIsGravatar(false);
                setLoading(false);
            } else if (emailHash) {
                setLoading(true);
                const url = getGravatarUrl(emailHash);
                const response = await fetch(url);
                setLoading(false);
                if (!response.ok) {
                    setImage(DEFAULT_GRAVATAR_PATH);
                    setIsGravatar(true);
                    return;
                }
                setImage(url);
                setIsGravatar(true);
            } else {
                setImage(DEFAULT_GRAVATAR_PATH);
                setIsGravatar(true);
                setLoading(false);
            }
        };
        fetchImage();
    }, [emailHash, profileImageUrl, photoImageUrl, setLoading, setImage, setIsGravatar]);

    return { loading, image, isGravatar };
}
