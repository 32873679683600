import React from 'react';

interface Props {
    readonly className?: string;
}

export default function GitHub({
    className = null,
}: Props) {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_363_22785)">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.99853 0.875C5.47519 0.87579 4.0018 1.41533 2.84182 2.39713C1.68184 3.37894 0.910914 4.739 0.666883 6.23411C0.422852 7.72922 0.72163 9.2619 1.5098 10.5581C2.29797 11.8542 3.52413 12.8294 4.96903 13.3092C5.2878 13.3683 5.40787 13.1708 5.40787 13.0028C5.40787 12.8348 5.40149 12.3477 5.39937 11.8153C3.61426 12.2009 3.23705 11.062 3.23705 11.062C2.94591 10.3224 2.52514 10.128 2.52514 10.128C1.94285 9.73286 2.5687 9.74025 2.5687 9.74025C3.21368 9.78568 3.55264 10.3985 3.55264 10.3985C4.1243 11.3736 5.05404 11.0915 5.41956 10.9267C5.47693 10.5136 5.64376 10.2326 5.82758 10.0731C4.40162 9.91247 2.90341 9.36519 2.90341 6.9204C2.89457 6.28636 3.1312 5.6732 3.56433 5.20779C3.49845 5.04719 3.27849 4.39849 3.62701 3.51736C3.62701 3.51736 4.16574 3.3462 5.39194 4.17134C6.44369 3.88536 7.55337 3.88536 8.60512 4.17134C9.83026 3.3462 10.3679 3.51736 10.3679 3.51736C10.7175 4.39638 10.4976 5.04508 10.4317 5.20779C10.8662 5.67328 11.1033 6.28751 11.0936 6.92252C11.0936 9.37258 9.59224 9.91247 8.16416 10.0699C8.39367 10.2685 8.59875 10.6563 8.59875 11.2521C8.59875 12.1058 8.59131 12.7925 8.59131 13.0028C8.59131 13.1729 8.70713 13.3715 9.03227 13.3092C10.4773 12.8293 11.7036 11.854 12.4918 10.5576C13.2799 9.26123 13.5786 7.72832 13.3342 6.23307C13.0899 4.73782 12.3186 3.37775 11.1583 2.39611C9.9979 1.41447 8.52417 0.875289 7.00065 0.875H6.99853Z" />
                <path d="M3.01164 10.0361C2.99782 10.0678 2.94682 10.0773 2.90538 10.0551C2.86394 10.0329 2.83313 9.99171 2.84801 9.95895C2.86288 9.9262 2.91282 9.91775 2.95426 9.93994C2.9957 9.96212 3.02758 10.0044 3.01164 10.0361Z" />
                <path d="M3.27198 10.3245C3.24997 10.3355 3.22479 10.3386 3.20076 10.3332C3.17672 10.3278 3.15532 10.3143 3.14022 10.2949C3.09878 10.2505 3.09027 10.1893 3.12215 10.1618C3.15403 10.1343 3.21141 10.147 3.25285 10.1914C3.29429 10.2358 3.30385 10.297 3.27198 10.3245Z" />
                <path d="M3.52488 10.6911C3.48557 10.7186 3.41862 10.6911 3.38143 10.6362C3.37115 10.6263 3.36297 10.6145 3.35738 10.6014C3.3518 10.5884 3.34891 10.5743 3.34891 10.5601C3.34891 10.5459 3.3518 10.5319 3.35738 10.5188C3.36297 10.5057 3.37115 10.4939 3.38143 10.484C3.42075 10.4576 3.48769 10.484 3.52488 10.5379C3.56207 10.5918 3.56313 10.6636 3.52488 10.6911Z" />
                <path d="M3.86808 11.0461C3.83301 11.0852 3.76182 11.0746 3.70338 11.0218C3.64494 10.969 3.63112 10.8971 3.66619 10.8591C3.70125 10.8211 3.77245 10.8316 3.83301 10.8834C3.89358 10.9352 3.90527 11.0081 3.86808 11.0461Z" />
                <path d="M4.3494 11.2532C4.33347 11.3028 4.26121 11.325 4.18896 11.3039C4.1167 11.2828 4.06889 11.2236 4.0827 11.1729C4.09651 11.1222 4.16983 11.0989 4.24315 11.1222C4.31646 11.1454 4.36322 11.2014 4.3494 11.2532Z" />
                <path d="M4.87433 11.2891C4.87433 11.3409 4.81483 11.3852 4.73833 11.3863C4.66182 11.3874 4.59913 11.3451 4.59913 11.2933C4.59913 11.2416 4.65863 11.1972 4.73514 11.1961C4.81164 11.1951 4.87433 11.2363 4.87433 11.2891Z" />
                <path d="M5.36311 11.2078C5.37268 11.2595 5.31955 11.3134 5.24304 11.3261C5.16654 11.3388 5.0996 11.3081 5.09003 11.2574C5.08047 11.2067 5.13572 11.1518 5.2101 11.138C5.28448 11.1243 5.35355 11.156 5.36311 11.2078Z" />
            </g>
            <defs>
                <clipPath id="clip0_363_22785">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
